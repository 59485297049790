@font-face {
    font-family: 'Anzeigen-Grotesk';
    src: url('../../fonts/anzeigen_grotesk_d_regular-webfont.woff') format('woff'),
        url('../../fonts/anzeigen_grotesk_d_regular-webfont.woff2') format('woff2');
}

.landing {
    position: absolute;
    width: 98dvw;
    height: auto;
    padding: 0 1dvw;
    z-index: 5;
}

.titleContainer {
    clip-path: inset(0);
    justify-content: center;
}

.title {
    
    font-family: 'Anzeigen-Grotesk', sans-serif;
    /* font-family: 'Neue-Machina', sans-serif; */
    color: white;
    font-size: 18.4vw;
    letter-spacing: -.5vw;
    transform: scale(1, 2);
    padding: 5dvw 0;
    margin: 0;
    /* text-align: justify; */
    margin-block-start: 0em;
    margin-block-end: 0em;
}

@media screen and (min-width: 768px) {
    .landing {
        width:  98dvw;
        height: auto;
        padding: 1dvw 1dvw;
    }
    .title {
        font-size: 18.4vw;
        transform: scale(1, 2);
        padding: 7dvh 0;
        
        /* font-weight: 200; */
        /* font-size: 8.6vw; */
    }
}