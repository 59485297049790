@font-face {
    font-family: 'Anzeigen-Grotesk';
    src: url('../../fonts/anzeigen_grotesk_d_regular-webfont.woff') format('woff'),
        url('../../fonts/anzeigen_grotesk_d_regular-webfont.woff2') format('woff2');
}

.loader {
    /* visibility: hidden; */
    width: 100dvw;
    height: 100dvh;
    position: absolute;
    z-index: 100;
    background-color: #c2c2c3;
    /* background-image: linear-gradient(rgba(142, 142, 142, 1), rgba(142, 142, 142, .5)); */
    display: flex;
    justify-content: center;
    align-items: center;
    clip-path: inset(0 0 0 0);
}

.typeBox {
    clip-path: inset(0 0 0 0);
    width: 80%;
}

.logoType {
    /* font-family: 'Anzeigen-Grotesk', sans-serif; */
    font-family: 'Open-Sans', sans-serif;
    color: black;
    font-size: 5vw;
    padding: 2.5dvh 0;
    text-align: center;
}

.line {
    border-top: 2px solid black;
    clip-path: inset(0 0 0 0);
}

@media screen and (min-width: 768px) {
    .typeBox {
        width: 50%;
    }
    .logoType {
        font-size: 1.5vw;
        text-align: center;
    }
}