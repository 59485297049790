html {
    overflow-y: hidden;
}

.app {
    width: 100dvw;
    height: 100dvh;
    padding: 0;
    margin: 0;
    background-color: black;
}
