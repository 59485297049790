.about {
    position: absolute;
    width: 80dvw;
    height: 100dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    color:  white;
    padding: 0 10dvw;
    z-index: 10;
    pointer-events: none;
}
.copy p {
    font-family: 'Open Sans', sans-serif;
    font-size: 3.5vw;
    text-align: justify;
    clip-path: inset(0 0 0 0);
}

@media screen and (min-width: 768px) {
    .about {
        width: 60dvw;
        height: 75dvh;
        padding: 12.5dvh 1.5dvw;
        align-items: flex-end;
    }
    .copy p {
        font-size: 1.5vw;
        text-align: left;
    }
}

@media screen and (min-width: 900px) {
    .copy p {
        font-size: 1.25vw;
    }
}

@media screen and (min-width: 1200px) {
    .about {
        width: 60dvw;
        height: 80dvh;
        padding: 10dvh 1.5dvw;
    }
    .copy p {
        font-size: 1vw;
    }
}