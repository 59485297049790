.menu {
    position: absolute;
    width: 97dvw;
    height: auto;
    bottom: 0dvh;
    margin: 1.5dvw 1.5dvw;
    display: flex;
    justify-content: space-between;
    z-index: 99;
    clip-path: inset(0);
}

a.navLink {
    text-decoration: none;
    cursor: pointer;
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 200;
}

@media screen and (min-width: 768px) {
    .menu {
        width: 97dvw;
        height: auto;
        bottom: 0dvh;
        margin: 1.5dvw 1.5dvw;
        display: flex;
        justify-content: space-between;
        z-index: 10;
    }
}