body {
  margin: 0;
  padding: 0;
  width: 100dvw;
  height: 100dvh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
